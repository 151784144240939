<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
            <!-- <v-card> -->
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                clearable
                                v-model="office"
                                :items="offices"
                                item-value="office_id"
                                item-text="office"
                                label="Office ID"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                clearable
                                v-model="warehouse"
                                :items="warehouses"
                                item-value="wh_id"
                                :item-text="item => item.wh_id +' - '+ item.descr"
                                label="Warehouse ID"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                clearable
                                v-model="category"
                                :items="categories"
                                item-value="cat_id"
                                item-text="descr"
                                label="Category"
                                @change="(event) => updateArticle(event)"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                clearable
                                v-model="article"
                                :items="articles"
                                item-value="wh_id"
                                item-text="description"
                                label="Article"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-btn
                                class="mr-2"
                                color="info"
                                elevation="2"
                                large
                                rounded
                                @click="submit()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-container>
            <!-- </v-card> -->
        </v-col>
        <v-col cols="12" style="padding-bottom: 100px">
            <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
            <v-card >
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>Result 
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="$store.state.text"
                                    append-icon="mdi-magnify"
                                    label="Search..."
                                    single-line
                                    @keyup.enter="search"
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="warehouse_stocks"
                                :options.sync="options"
                                :loading="loading"
                                loading-text="Please wait, retrieving data"
                                :server-items-length="totalItems"
                                :page.sync="pagination"
                                page-count="10"
                                class="elevation-1"
                            >
                                <template v-slot:[`header.qty_oh`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`item.qty_oh`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.qty_oh) }}
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SR MALL',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'Information',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Warehouse Stock',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            loading_loader: false,
            offices: [],
            office: '',
            warehouses: [],
            warehouse: '',
            categories: [],
            category: '',
            articles: [],
            article: '',
            date: '',
            menu: false,
            modal: false,
            statuss: [
                {
                    value: 'A',
                    label: 'All'
                },
                {
                    value: 'O',
                    label: 'Belum Dibayar'
                },
                {
                    value: 'C',
                    label: 'Sudah Dibayar'
                }
            ],
            status: '',
            period: '',
            options: {},
            display: 'none',
            total_piutang: 0,
            headers: [
                {
                    text: 'BIN',
                    align: 'start',
                    sortable: false,
                    value: 'bin_id',
                },
                { text: 'Warehouse', value: 'whouse', sortable: false },
                { text: 'Category', value: 'category_name', sortable: false },
                { text: 'Article', value: 'article_name', sortable: false },
                { text: 'Qty OH', value: 'qty_oh', sortable: false }
            ],
            warehouse_stocks: [],
            totalItems: 10,
            pagination: 1,
            sortBy: 'desc',
            sortName: ''
        }
    },
    mounted(){
        this.getOffice()
        this.getWarehouse()
        this.getCategory()
        this.getArticle()
    },
    methods:{
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
                this.office = res.data[0].office_id
            });
        },
        async getWarehouse(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/whouse`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.warehouses = res.data
            });
        },
        async getCategory(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/category`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.categories = res.data
            });
        },
        updateArticle(value){
            this.category = value
            this.getArticle()
        },
        async getArticle(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/article?cat_id=${this.category ? this.category : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.articles = res.data
            });
        },
        search(){
            this.warehouse_stocks = []
            this.pagination = 1
            this.submit(1, 10)
        },
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(page = 1, itemsPerPage = 10){ 

            this.loading = true
            this.loading_loader = true
            this.display = 'block'

            await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/warehouse?search=${this.$store.state.text}&txtOffice=${this.office}&wh_id=${this.warehouse ? this.warehouse : ''}&cat_id=${this.category ? this.category : ''}&article_id=${this.article ? this.article : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false
                this.warehouse_stocks = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        }
    },
    watch: {
        options: {
        handler () {
            // this.loading = true
            const { page, itemsPerPage } = this.options
            if (this.office != '') {
                this.submit(page, itemsPerPage)
            }
        },
        deep: true
      }
    }
}
</script>